export const CONSTANTS = {
  LOCAL_STORAGE: {
    TOKEN: "token",
    USER_DATA: "userData",
    LOCALE: "locale",
    ORG_ACCOUNT: "org-account",
    WORKPLACE_ID: "workplace-id",
    DEVICE_INFO: "deviceInfo",
    IS_KEYBOARD_SHOW: "isKeyboard",
  },
};

export const DESKTOP_WIDTH = 1024;
export const TABLET_WIDTH = 991;
export const MOBILE_WIDTH = 768;

export const LOCALE = "i18reactLng";

export const LANGUAGE_OPTION = {
  VN: {
    LANG: "vi",
  },

  EN: {
    LANG: "en",
  },

  CN: {
    LANG: "cn",
  },
};

export const TYPE_COUPON = {
  TIME: {
    VALUE: "TIME",
    LABEL: "Thời gian",
  },

  NEWBIE: {
    VALUE: "NEWBIE",
    LABEL: "Người mới",
  },

  QUANTITY_LIMIT: {
    VALUE: "QUANTITY_LIMIT",
    LABEL: "Số lượng có hạn",
  },

  REWARD: {
    VALUE: "REWARD",
    LABEL: "Đổi thưởng",
  },
};

export const RANK_TYPE = {
  SILVER: {
    VALUE: "SILVER",
    LABEL: "Bạc",
    COLOR: "#D3D3D3",
  },
  GOLD: {
    VALUE: "GOLD",
    LABEL: "Vàng",
    COLOR: "#ffc60a",
  },
  NORMAL: {
    VALUE: "NORMAL",
    LABEL: "Thuờng",
    COLOR: "#198754",
  },
  NO_RANK: {
    VALUE: "",
    LABEL: "Chưa có hạng",
    COLOR: "#ff6b81",
  },
};

export const TYPE_REWARD_POINT = {
  EGO_TRIP: {
    VALUE: "EGO_TRIP",
    LABEL: "Taxi",
  },
  RENT_CAR: {
    VALUE: "RENT_CAR",
    LABEL: "Thuê xe",
  },
  EXPRESS: {
    VALUE: "EXPRESS",
    LABEL: "Giao hàng",
  },
  REWARD: {
    VALUE: "REWARD",
    LABEL: "Đổi thưởng",
  },
  ADMIN_UPDATE: {
    VALUE: "ADMIN_UPDATE",
    LABEL: "Admin cập nhật",
  },
};

export const ACTIVITY_REWARD_POINT = {
  USE: {
    VALUE: "USE",
    LABEL: "Sử dụng",
  },

  RECEIVE: {
    VALUE: "RECEIVE",
    LABEL: "Tích điểm",
  },

  ADMIN_UPDATE: {
    VALUE: "ADMIN_UPDATE",
    LABEL: "Admin cập nhật",
  },
};

export const TYPE_PAYMENT = {
  DEPOSIT: {
    VALUE: "DEPOSIT",
    LABEL: "Nạp tiền",
  },

  WITHDRAW: {
    VALUE: "WITHDRAW",
    LABEL: "Rút tiền",
  },

  PAYMENT: {
    VALUE: "PAYMENT",
    LABEL: "Thanh toán",
  },

  LINK_CARD: {
    VALUE: "LINK_CARD",
    LABEL: "Liên kết thẻ",
  },

  TRANS_MONEY_TO_EWALLET: {
    VALUE: "TRANS_MONEY_TO_EWALLET",
    LABEL: "Chuyển tiền qua ví",
  },

  RECEIVE_MONEY_TO_WALLET: {
    VALUE: "RECEIVE_MONEY_TO_WALLET",
    LABEL: "Chuyển tiền qua ví",
  },

  DOWN_PAYMENT: {
    VALUE: "DOWN_PAYMENT",
    LABEL: "Đặt cọc",
  },

  REFUND: {
    VALUE: "REFUND",
    LABEL: "Hoàn tiền",
  },

  SAVING: {
    VALUE: "SAVING",
    LABEL: "Đầu tư tiết kiệm"
  }
};

export const TYPE_PAYMENT_TRANSACTION = {
  CASH: {
    VALUE: "CASH",
    LABEL: "Tiền mặt",
  },

  QR_CODE: {
    VALUE: "QR_CODE",
    LABEL: "Qr Code",
  },

  E_WALLET: {
    VALUE: "E_WALLET",
    LABEL: "Ví Ego",
  },

  ATM: {
    VALUE: "ATM",
    LABEL: "ATM",
  },

  VISA: {
    VALUE: "VISA",
    LABEL: "Thẻ visa",
  },

  MASTERCARD: {
    VALUE: "MASTERCARD",
    LABEL: "Master card",
  },

  ADMIN: {
    VALUE: "ADMIN",
    LABEL: "Admin",
  },
};

export const LOCAL_STORAGE = {
  TOKEN: "token",
  USER_DATA: "userData",
};

export const CONSTANTS_VALIDATE = {
  REGEX_PHONE: /(84|0[3|5|7|8|9])+([0-9]{8})\b/g,
  REGEX_PHONE_NO_ZERO: /(84[3|5|7|8|9])+([0-9]{8})\b/g,
  REGEX_EMAIL: /^\S+@\S+\.\S+$/,
  REGEX_IDENTITYCARD: /^\d{0,12}$/,
  REGEX_PRICE: /^\d{0,7}$/,
  REGEX_DISCOUNT_RATE: /^-?\d*(\.\d+)?$/,
  REGEX_DISCOUNT: /^\d{0,9}$/,
  REGEX_PERCENT: /^(0|[1-9][0-9]?|100)$/,
};

export const DATE_TIME_FORMAT = {
  DMY: "DD/MM/YYYY",
  DMY_SNAKE: "DD-MM-YYYY",
  YMD_SNAKE: "YYYY-MM-DD",
  DMY_Hm: "DD/MM/YYYY HH:mm",
  Hm: "HH:mm",
};

export const ROLE = {
  ADMIN: "ADMIN",
  SUPER_ADMIN: "SUPER_ADMIN",
  PARTNER: "PARTNER",
  PARTNER_SHOP: "PARTNER_SHOP",
  ADMIN_BY_REGION: "ADMIN_BY_REGION",
  HEADQUARTERS: "HEADQUARTERS",
  GENERAL_MANAGER: "GENERAL_MANAGER",
  BOOKING_MANAGER: "BOOKING_MANAGER",

  SUPPORT_MANAGER: "SUPPORT_MANAGER",

  SWITCHBOARD_OPERATOR: "SWITCHBOARD_OPERATOR",

  PARTNER_SUPPORT: "SUPPORT_PARTNER",
  EGO_PARTNERS_MANAGER: "EGO_PARTNERS_MANAGER",

  PARTNER_STAFF: "PARTNER_STAFF",
  INVEST_MANAGER: "INVEST_MANAGER",

  CLIENT: "CLIENT",

  GOVERNMENT: "GOVERNMENT"
};

export const DISCOUNT_TYPE = {
  VALUE: 'AMOUNT',
  PERCENT: 'PERCENT',
}

export const ROLE_STAFF_PARTNER = {
  CEO: {
    value: "CEO",
    label: "CEO",
  },

  BIZ_MGR: {
    value: "BIZ_MGR",
    label: "Quản lý kinh doanh",
  },

  FLEET_MGR: {
    value: "FLEET_MGR",
    label: "Quản lý xe",
  },

  FIN_HR_MGR: {
    value: "FIN_HR_MGR",
    label: "Quản lý tài chính + nhân sự",
  },

  TECHNICIAN: {
    value: "TECHNICIAN",
    label: "Kỹ thuật viên",
  },
};

export const ROLE_STAFF = {
  GENERAL_MANAGER: {
    value: "GENERAL_MANAGER",
    label: "Quản lý tổng hợp",
  },

  BOOKING_MANAGER: {
    value: "BOOKING_MANAGER",
    label: "Quản lý booking",
  },

  EGO_PARTNERS_MANAGER: {
    value: "EGO_PARTNERS_MANAGER",
    label: "Quản lý partner",
  },

  SWITCHBOARD_OPERATOR: {
    value: "SWITCHBOARD_OPERATOR",
    label: "Tổng đài viên",
  },
};

export const ROLE_LABEL = {
  CLIENT: "Khách hàng",
  DRIVER: "Tài xế",
};

export const STATUS_LABEL = {
  SUCCESS: {
    value: "SUCCESS",
    label: "Thành công",
  },

  REQUEST: {
    value: "REQUEST",
    label: "Yêu cầu",
  },

  PENDING: {
    value: "PENDING",
    label: "Chờ giải quyết",
  },

  APPROVE: {
    value: "APPROVE",
    label: "Đang xử lý",
  },

  REFUSE: {
    value: "REFUSE",
    label: "Từ chối",
  },

  FAIL: {
    value: "FAIL",
    label: "Huỷ bỏ",
  },
};

export const STATUS_TAXI = {
  PENDING: {
    value: "PENDING",
    label: "Chờ duyệt",
  },

  REFUSED: {
    value: "REFUSED",
    label: "Từ chối",
  },

  CANCEL: {
    value: "CANCEL",
    label: "Huỷ bỏ",
  },

  SUCCESS: {
    value: "SUCCESS",
    label: "Thành công",
  },
};

export const STATUS_ORDER_SHOPPING = {
  PENDING: {
    value: "PENDING",
    label: "Chờ duyệt",
  },

  APPROVE: {
    value: "APPROVE",
    label: "Chấp nhận",
  },

  DELIVERING: {
    value: "DELIVERING",
    label: "Đang giao",
  },

  REFUSED: {
    value: "REFUSED",
    label: "Từ chối",
  },

  CANCEL: {
    value: "CANCEL",
    label: "Huỷ bỏ",
  },

  SUCCESS: {
    value: "SUCCESS",
    label: "Thành công",
  },
};

export const STATUS_BOOKING = {
  PENDING: {
    value: "PENDING",
    label: "Chờ duyệt",
  },

  APPROVE: {
    value: "APPROVE",
    label: "Đã duyệt",
  },

  DELIVERING: {
    value: "DELIVERING",
    label: "Đang giao xe",
  },

  RENTING: {
    value: "RENTING",
    label: "Đang cho thuê",
  },

  REFUSE: {
    value: "REFUSE",
    label: "Từ chối",
  },

  CANCEL: {
    value: "CANCEL",
    label: "Huỷ bỏ",
  },

  SUCCESS: {
    value: "SUCCESS",
    label: "Thành công",
  },
};

export const STATUS_DELIVERY = {
  PENDING: {
    value: "PENDING",
    label: "Chờ duyệt",
  },
  APPROVE: {
    value: "APPROVE",
    label: "Đã duyệt",
  },

  READY: {
    value: "READY",
    label: "Sẵn sàng",
  },

  ARRIVED: {
    value: "ARRIVED",
    label: "Đã đến",
  },

  STARTING: {
    value: "STARTING",
    label: "Đang vận chuyển",
  },

  CANCEL: {
    value: "CANCEL",
    label: "Huỷ bỏ",
  },

  CLIENT_CANCEL_BOOKING: {
    value: "CLIENT_CANCEL_BOOKING",
    label: "Huỷ bỏ",
  },

  DRIVER_CANCEL_BOOKING: {
    value: "DRIVER_CANCEL_BOOKING",
    label: "Huỷ bỏ",
  },

  ADMIN_CANCEL_BOOKING: {
    value: "ADMIN_CANCEL_BOOKING",
    label: "Huỷ bỏ",
  },

  SUCCESS: {
    value: "SUCCESS",
    label: "Thành công",
  },

  REFUSED: {
    value: "REFUSED",
    label: "Từ chối",
  },
};

export const STATUS_DELIVERY_SHOP = {
  PENDING: {
    value: "PENDING",
    label: "Chờ duyệt",
  },

  DELIVERING: {
    value: "DELIVERING",
    label: "Đang giao",
  },

  CANCEL: {
    value: "CANCEL",
    label: "Huỷ bỏ",
  },

  SUCCESS: {
    value: "SUCCESS",
    label: "Thành công",
  },
};

export const ACCOUNT_TYPE = {
  PARTNER: {
    VALUE: "PARTNER",
    TYPE: "Internal",
    LABEL: "Partner",
  },

  EGO: {
    VALUE: "EGO",
    TYPE: "Ego",
    LABEL: "Ego",
  },
};

export const BUSINESS_RULES = {
  SELF_DRIVING_CAR: {
    VALUE: "CAR_RENTAL",
    LABEL: "Cho thuê",
  },

  TAXI: {
    VALUE: "TAXI",
    LABEL: "Taxi",
  },

  BUS: {
    VALUE: "BUS_TICKET_SALES",
    LABEL: "Xe khách",
  },

  CARGO_TRANS: {
    VALUE: "CARGO_TRANS",
    LABEL: "Giao hàng",
  },

  CAR_RENTAL_TAXI: {
    VALUE: ["CAR_RENTAL", "TAXI"],
    LABEL: ["Cho thuê", "Taxi"],
  },

  CAR_RENTAL: {
    VALUE: "CAR_RENTAL",
    LABEL: "Xe thuê",
  },

  INTER_PROV_CAR: {
    VALUE: "INTER_PROV_CAR",
    LABEL: "Xe liên tỉnh",
  },
};

export const BUSINESS_OPTIONS = [
  {
    VALUE: "TAXI",
    LABEL: "Taxi",
  },

  {
    VALUE: "CAR_RENTAL",
    LABEL: "Cho thuê",
  },

  // {
  //   VALUE: "BUS",
  //   LABEL: "Xe khách",
  // },

  // {
  //   VALUE: "CARGO_TRANS",
  //   LABEL: "Vận chuyển hàng hóa",
  // },
];

export const OPTIONS_FILTER_INVOICE = [
  {
    VALUE: "TAXI",
    LABEL: "Taxi",
  },

  {
    VALUE: "CAR_RENTAL",
    LABEL: "Cho thuê",
  },
];

export const TYPE_PRICE = {
  NEW_PRICE: {
    VALUE: "NEW_PRICE",
    LABEL: "New price",
  },

  UPDATE_PRICE: {
    VALUE: "UPDATE_PRICE",
    LABEL: "Update price",
  },

  DELETE_PRICE: {
    VALUE: "DELETE_PRICE",
    LABEL: "Delete price",
  },
};

export const TRANSPORTATION_TYPE = {
  CAR: "OTO",
  MOTO: "MOTO",
  BUS: "BUS",
  CARGO_VEHICLE: "CARGO_VEHICLE",
};

export const BUSINESS_TYPE = {
  TAXI: "TAXI",
  RENT_CAR: "CAR_RENTAL",
  BUS: "BUS_TICKET_SALES",
  CARGO_TRANS: "CARGO_TRANS",
};

export const TRANSPORTATION_TYPE_OPTIONS = [
  { value: TRANSPORTATION_TYPE.CAR, label: "Ô tô" },
  { value: TRANSPORTATION_TYPE.MOTO, label: "Mô tô" },
];

export const CAR_MANUFACTURER = {
  ["TOYOTA"]: "Toyota",
  ["HYUNHDAI"]: "Huyndai",
  ["HONDA"]: "Honda",
  ["BMW"]: "BMW",
  ["VINFAST"]: "Vinfast",
  ["MERCEDES"]: "Mercedes Benz",
  ["MERCEDES BENZ"]: "Mercedes Benz",
  ["KIA"]: "KIA",
  ["SUZUKI"]: "Suzuki",
  ["MAZDA"]: "Mazda",
  ["NISSAN"]: "Nissan",
  ["DAEWOO"]: "Daewoo",
  ["MITSUBISHI"]: "Mitsubishi",
  ["MG"]: "MG",
  ["AUDI"]: "Audi",
  ["FORD"]: "Ford",
  ["THACO"]: "Thaco",
  ["IVECO"]: "Iveco",
  ["OTHER"]: "Khác",
};

export const BIKE_MANUFACTURER = {
  HONDA: "Honda",
  VINFAST: "Vinfast",
  SUZUKI: "Suzuki",
  YAMAHA: "Yamaha",
};

export const FUEL_TYPE = {
  ["xăng"]: {
    COLOR: "#337EBA",
    LABEL: "Xăng",
  },
  ["điện"]: {
    COLOR: "#29C76F",
    LABEL: "Điện",
  },
  ["dầu"]: {
    COLOR: "#B9CCD3",
    LABEL: "Dầu",
  },
};

export const COLOR_LABEL = {
  ["XANH"]: {
    LABEL: "Xanh",
    COLOR: "#063A6C",
  },
  ["ĐỎ"]: {
    LABEL: "Đỏ",
    COLOR: "#961D25",
  },
  ["TRẮNG"]: {
    LABEL: "Trắng",
    COLOR: "#FFF",
  },
  ["ĐEN"]: {
    LABEL: "Đen",
    COLOR: "#000",
  },
  ["VÀNG"]: {
    LABEL: "Vàng",
    COLOR: "#D98932",
  },
  ["BẠC"]: {
    LABEL: "Bạc",
    COLOR: "#4A4F53",
  },

  ["OTHER"]: {
    LABEL: "Khác",
    COLOR: "#B504A3",
  },
};

export const PAGE_LIMIT = 10;

/**
 * @description account setup
 */
export const ACCOUNT_BUSINESS_TYPE = {
  ["TRANSPORTATION"]: {
    VALUE: "TRANSPORTATION",
    LABEL: "Vận tải",
  },

  ["SHOPPING"]: {
    VALUE: "SHOPPING",
    LABEL: "Mua sắm",
  },

  ["TRAVEL"]: {
    VALUE: "TRAVEL",
    LABEL: "Du lịch",
  },
};

export const NEW_TYPE = {
  ["COMPANY"]: {
    VALUE: "COMPANY",
    LABEL: "Công ty",
  },

  ["PROMOTION"]: {
    VALUE: "PROMOTION",
    LABEL: "Ưu đãi",
  },

  ["COMMUNITY"]: {
    VALUE: "COMMUNITY",
    LABEL: "Cộng đồng",
  },

  ["NEWSPAPER"]: {
    VALUE: "NEWSPAPER",
    LABEL: "Báo chí",
  },

  ["DRIVER"]: {
    VALUE: "DRIVER",
    LABEL: "Tài xế EGO",
  },

  ["INVESTORS"]: {
    VALUE: "INVESTORS",
    LABEL: "Đầu tư",
  },
};

export const TRANSPORTATION_TYPES_FILTER = {
  ["TAXI"]: {
    VALUE: "TAXI",
    LABEL: "Taxi",
  },

  ["CAR_RENTAL"]: {
    VALUE: "CAR_RENTAL",
    LABEL: "Xe thuê, Xe liên tỉnh",
  },

  ["CARGO_TRANS"]: {
    VALUE: "CARGO_TRANS",
    LABEL: "Giao hàng",
  },
};

export const TRANSPORTATION_TYPES_PARTNER = {
  ["TAXI"]: {
    VALUE: "TAXI",
    LABEL: "Taxi",
  },

  ["CAR_RENTAL"]: {
    VALUE: "CAR_RENTAL",
    LABEL: "Xe thuê",
  },

  ["CARGO_TRANS"]: {
    VALUE: "CARGO_TRANS",
    LABEL: "Giao hàng",
  },

  ["INTER_PROV_CAR"]: {
    VALUE: "INTER_PROV_CAR",
    LABEL: "Xe liên tỉnh",
  },
};

export const TRANSPORTATION_BUSINESS_TYPES = {
  ["TAXI"]: {
    VALUE: "TAXI",
    LABEL: "Taxi",
  },

  ["CAR_RENTAL"]: {
    VALUE: "CAR_RENTAL",
    LABEL: "Xe thuê",
  },

  ["CAR_RENTAL_V2"]: {
    VALUE: "CAR_RENTAL_V2",
    LABEL: "Xe thuê",
  },

  ["CARGO_TRANS"]: {
    VALUE: "CARGO_TRANS",
    LABEL: "Giao hàng",
  },

  ["INTER_PROV_CAR"]: {
    VALUE: "INTER_PROV_CAR",
    LABEL: "Xe liên tỉnh",
  },
};

export const ADDRESS_TYPE = {
  ["ADDRESS"]: {
    VALUE: "ADDRESS",
    LABEL: "Địa chỉ",
  },

  ["PARKING_LOT"]: {
    VALUE: "PARKING_LOT",
    LABEL: "Bãi đậu xe",
  },
};

export const SHOPPING_BUSSINESS_TYPES = {
  ["RESTAURANT"]: {
    VALUE: "RESTAURANT",
    LABEL: "Nhà hàng",
  },

  ["SHOP"]: {
    VALUE: "SHOP",
    LABEL: "Tạp hóa",
  },
};

export const TRAVEL_BUSINESS_TYPE = {
  ["TICKET_SALES_FOR_SERVICES"]: {
    VALUE: "TICKET_SALES_FOR_SERVICES",
    LABEL: "Đặt vé dịch vụ",
  },
};

export const CONFIRM = {
  ["YES"]: {
    VALUE: "YES",
    LABEL: "Có",
  },

  ["NO"]: {
    VALUE: "NO",
    LABEL: "Không",
  },
};

export const CAR_HOST = {
  ["DEFAULT"]: {
    VALUE: "DEFAULT",
    LABEL: "Công ty",
  },

  ["OTHER"]: {
    VALUE: "OTHER",
    LABEL: "Chủ khác",
  },
};

export const TYPE_CAR = {
  ECAR_8_SEATER_ELECTRIC: {
    VALUE: "ECAR_8_SEATER_ELECTRIC",
    LABEL: "eCar 8 chỗ (Điện)",
  },

  ETAXI_4_SEATER_PETROL: {
    VALUE: "ETAXI_4_SEATER_PETROL",
    LABEL: "eTaxi 4 chỗ (Xăng)",
  },

  ETAXI_4_SEATER_ELECTRIC: {
    VALUE: "ETAXI_4_SEATER_ELECTRIC",
    LABEL: "eTaxi 4 chỗ (Điện)",
  },

  ETAXI_7_SEATER_PETROL: {
    VALUE: "ETAXI_7_SEATER_PETROL",
    LABEL: "eTaxi 7 chỗ (Xăng)",
  },

  ETAXI_7_SEATER_ELECTRIC: {
    VALUE: "ETAXI_7_SEATER_ELECTRIC",
    LABEL: "eTaxi 7 chỗ (Điện)",
  },

  OTO_4_SEATER_PETROL: {
    VALUE: "OTO_4_SEATER_PETROL",
    LABEL: "Xe 4 chỗ (Xăng)",
  },

  OTO_4_SEATER_ELECTRIC: {
    VALUE: "OTO_4_SEATER_ELECTRIC",
    LABEL: "Xe 4 chỗ (Điện)",
  },

  OTO_7_SEATER_PETROL: {
    VALUE: "OTO_7_SEATER_PETROL",
    LABEL: "Xe 7 chỗ (Xăng)",
  },

  OTO_7_SEATER_ELECTRIC: {
    VALUE: "OTO_7_SEATER_ELECTRIC",
    LABEL: "Xe 7 chỗ (Điện)",
  },

  MOTO_BIKE_PETROL: {
    VALUE: "MOTO_BIKE_PETROL",
    LABEL: "Xe máy (Xăng)",
  },

  MOTO_BIKE_ELECTRIC: {
    VALUE: "MOTO_BIKE_ELECTRIC",
    LABEL: "Xe máy (Điện)",
  },

  MOTO: {
    VALUE: "MOTO",
    LABEL: "Xe máy",
  },

  OTO_16_SEATER: {
    VALUE: "OTO_16_SEATER",
    LABEL: "Xe 16 chỗ",
  },

  OTO_29_SEATER: {
    VALUE: "OTO_29_SEATER",
    LABEL: "Xe 29 chỗ",
  },

  OTO_45_SEATER: {
    VALUE: "OTO_45_SEATER",
    LABEL: "Xe 45 chỗ",
  },

  OTO_4_SEARTER: {
    VALUE: "OTO_4_SEARTER",
    LABEL: "Xe 4 chỗ",
  },

  OTO_7_SEARTER: {
    VALUE: "OTO_7_SEARTER",
    LABEL: "Xe 7 chỗ",
  },
};

export const TYPE_SERVICE = {
  BOOKING_BY_MOTO: {
    VALUE: "BOOKING_BY_MOTO",
    LABEL: "Chở khách/Giao hàng bằng xe máy",
  },

  BOOKING_BY_OTO: {
    VALUE: "BOOKING_BY_OTO",
    LABEL: "Chở khách bằng oto",
  },
  TAXI_BY_COMPANY: {
    VALUE: "TAXI_BY_COMPANY",
    LABEL: "Taxi công ty",
  },
  CAR_RENTAL: {
    VALUE: "CAR_RENTAL",
    LABEL: "Thuê xe máy / oto",
  },
  SHIPPING_BY_TRUCK: {
    VALUE: "SHIPPING_BY_TRUCK",
    LABEL: "xe tải giao hàng",
  },
  INTERPROV_VEHICLE: {
    VALUE: "INTERPROV_VEHICLE",
    LABEL: "Chở khách liên tỉnh",
  },
};

export const DRIVER_LICENSE_TYPE = {
  A1: {
    VALUE: "A1",
    LABEL: "A1",
  },
  B1: {
    VALUE: "B1",
    LABEL: "B1",
  },
  B2: {
    VALUE: "B2",
    LABEL: "B2",
  },
  C: {
    VALUE: "C",
    LABEL: "C",
  },
  D: {
    VALUE: "D",
    LABEL: "D",
  },
  F: {
    VALUE: "F",
    LABEL: "F",
  },
};

export const TYPE_LOG_BOOKING = {
  NEW_BOOKING: {
    VALUE: "NEW_BOOKING",
    LABEL: "Booking mới",
  },
  APPROVE_TRIP: {
    VALUE: "APPROVE_TRIP",
    LABEL: "Duyệt Booking",
  },
  RECEIVE_CAR: {
    VALUE: "RECEIVE_CAR",
    LABEL: "Giao xe",
  },
  FINISH_TRIP: {
    VALUE: "FINISH_TRIP",
    LABEL: "Hoàn thành booking",
  },
  CANCEL_TRIP: {
    VALUE: "CANCEL_TRIP",
    LABEL: "Huỷ chuyến",
  },

  START_TRIP: {
    VALUE: "START_TRIP",
    LABEL: "Bắt đầu giao hàng",
  },

  ARRIVED: {
    VALUE: "ARRIVED",
    LABEL: "Đã đến lấy hàng",
  },

  DRIVER_ACCEPT_BOOKING: {
    VALUE: "DRIVER_ACCEPT_BOOKING",
    LABEL: "Tài xế chấp nhận booking",
  },

  DRIVER_REFUSE_BOOKING: {
    VALUE: "DRIVER_REFUSE_BOOKING",
    LABEL: "Tài xế từ chối booking",
  },

  CLIENT_CANCEL_BOOKING: {
    VALUE: "CLIENT_CANCEL_BOOKING",
    LABEL: "Khách huỷ bỏ",
  },

  DRIVER_CANCEL_BOOKING: {
    VALUE: "DRIVER_CANCEL_BOOKING",
    LABEL: "Tài xế huỷ bỏ",
  },

  ADMIN_CANCEL_BOOKING: {
    VALUE: "ADMIN_CANCEL_BOOKING",
    LABEL: "Huỷ bỏ",
  },

  REFUSE_TRIP: {
    VALUE: "REFUSE_TRIP",
    LABEL: "Từ chối",
  },
  CHANGE_PARTNER: {
    VALUE: "CHANGE_PARTNER",
    LABEL: "Chuyển đối tác",
  },
};

export const TYPE_LOG_TRIP = {
  NEW_BOOKING: {
    VALUE: "NEW_BOOKING",
    LABEL: "Booking mới",
  },
  APPROVE_TRIP: {
    VALUE: "APPROVE_TRIP",
    LABEL: "Duyệt Booking",
  },

  START_TRIP: {
    VALUE: "START_TRIP",
    LABEL: "Bắt đầu chuyến đi",
  },

  FINISH_TRIP: {
    VALUE: "FINISH_TRIP",
    LABEL: "Hoàn thành chuyến đi",
  },

  CANCEL_TRIP: {
    VALUE: "CANCEL_TRIP",
    LABEL: "Huỷ chuyến",
  },

  ARRIVED: {
    VALUE: "ARRIVED",
    LABEL: "Đã đến",
  },

  DRIVER_ACCEPT_BOOKING: {
    VALUE: "DRIVER_ACCEPT_BOOKING",
    LABEL: "Tài xế chấp nhận booking",
  },

  DRIVER_REFUSE_BOOKING: {
    VALUE: "DRIVER_REFUSE_BOOKING",
    LABEL: "Tài xế từ chối booking",
  },

  CLIENT_CANCEL_BOOKING: {
    VALUE: "CLIENT_CANCEL_BOOKING",
    LABEL: "Khách huỷ bỏ",
  },

  DRIVER_CANCEL_BOOKING: {
    VALUE: "DRIVER_CANCEL_BOOKING",
    LABEL: "Tài xế huỷ bỏ",
  },

  ADMIN_CANCEL_BOOKING: {
    VALUE: "ADMIN_CANCEL_BOOKING",
    LABEL: "Huỷ bỏ",
  },

  NAVIGATING: {
    VALUE: "NAVIGATING",
    LABEL: "Đang điều huớng",
  },

  REFUSE_TRIP: {
    VALUE: "REFUSE_TRIP",
    LABEL: "Từ chối",
  },

  CHANGE_PARTNER: {
    VALUE: "CHANGE_PARTNER",
    LABEL: "Chuyển đối tác",
  },
};

export const TYPE_CARGO_DELIVERY = {
  FOOD: {
    VALUE: "FOOD",
    LABEL: "Đồ ăn",
  },

  FRAGILE: {
    VALUE: "Hàng dễ vỡ",
    LABEL: "Đồ ăn",
  },

  CLOTHES: {
    VALUE: "CLOTHES",
    LABEL: "Quần áo",
  },

  OTHER: {
    VALUE: "OTHER",
    LABEL: "Khác",
  },
};
