// key of feature and page

export const KEYS = {
  SUPPER_ADMIN: {
    ACCOUNT: "ego.supper_admin.account",
    HASHTAG: "ego.hashtag",
  },

  GOVERNMENT: {
    REPORT: "report.government",
  },

  WEBVIEW: {
    INVEST_PAGE: "webview.invest_page",
    PAYMENT_PAGE: "webview.payment_page",
    INVEST_OWNER_DETAIL_PAGE: "webview.invest_owner_detailpage",

    ACCUMULATE_PAGE: "webview.accumulate_page",
    ACCUMULATE__DETAIL_PAGE: "webview.accumulate_detail_page",
    ACCUMULATE__PAYMENT_PAGE: "webview.accumulate_payment_page",

    EGO_WEB3: "webview.web3",
    STAKING_WEB3: "webview.staking",
    STAKING_ETH: "webview.staking-eth",

    WALLET_WEB3: "webview.wallet_web3",
    WALLET_WEB3_RECEIVE: "webview.wallet_web3_receive",
    WALLET_WEB3_SEND: "webview.wallet_web3_send",
    WALLET_WEB3_TRANSFER: "webview.wallet_web3_transfer",
    WALLET_WEB3_HISTORY: "webview.wallet_web3_history",
  },

  EGO: {
    INVEST_ACCOUNT_TITLE: "ego.invest_management.title",
    CLIENT_TITLE: "ego.staff_title",
    STAFF_TITLE: "ego.staff_title",
    BOOKING_TITLE: "ego.booking_title",
    DASHBOARD: "ego.dashboard",
    NOTICE_TITLE: "ego.notification_title",
    NOTICE_HISTORY: "ego.notification_history",
    MAP: "ego.map",
    CAR: "ego.cars",
    USER: "ego.user",
    KYC_USER: "ego.kyc_user",
    STAFF: "ego.staff",
    DRIVER: "ego.driver",
    ACCOUNT: "ego.account",
    BOOKING: "ego.booking",
    BOOKING_RENT: "ego.booking_rent",
    BOOKING_TAXI: "ego.booking_taxi",
    BOOKING_EXPRESS: "ego.booking_express",
    CAR_ADMIN: "ego.cars_admin",
    ACCOUNT_CONTACT: "ego.account_contact",

    HOTLINE: "ego.hotline",
    PAYMENT: "ego.payment",
    LOCATION: "ego.location",
    LOCATION_SUGGEST: "ego.location_suggest",

    EMPLOYEE: "ego.employee",
    INVOICES: "ego.invoices",
    PROMOTION: "ego.promotion",
    NOTIFICATION: "ego.notification",
    PRICE_CONFIG: "ego.price_config",
    PRICE_CONFIG_SETUP: "ego.price_config.setup",

    PRICE_CONFIG_SHOPPING_DELIVERY: "ego.price_config.delivery_shopping",

    DRIVER_DETAIL: "ego.driver_detail",
    BOOKING_DETAIL: "ego.booking_detail",
    LOCATION_DETAIL: "ego.location_detail",
    CUSTOMER_SERVICE: "ego.customer_service",
    NOTIFICATION_ADD: "ego.notification_add",
    NEWS: "ego.news",
    NEW_PARTNER: "ego.new_partner",

    ACCUMULATE_HEAD: "accumulate.title",
    ACCUMULATE_PROGRAM: "accumulate.program",
    ACCUMULATE_PROGRAM_ORDER: "accumulate.program_order",
  },

  PARTNER: {
    STAFF_TITLE: "partner.staff.title",
    CLIENT_TITLE: "partner.client.title",
    SETTING_TITLE: "partner.setting.title",

    PAYROLL: "partner.payroll",
    CLIENT: "partner.client",
    ACCOUNT: "partner.account",
    DASHBOARD: "partner.dashboard",
    DEPARTMENT: "partner.department",
    POSITION: "partner.position",

    CLIENT_DETAIL: "partner.client_detail",
    STAFF_MANAGEMENT: "partner.staff_management",

    REPORT_TITLE: "report.title",
    REPORT_PAGE: "report.page",

    REPORT: {
      BOOKING_BY_DATE: "partner.report.booking_by_date",
      BOOKING_BY_WEEK: "partner.report.booking_by_week",
      BOOKING_BY_MONTH: "partner.report.booking_by_month",
      BOOKING_BY_VEHICLE_TYPE: "partner.report.booking_by_vehicle_type",
      BOOKING_BY_AREA: "partner.report.booking_by_area",
      BOOKING_BY_PICKUP_LOCATION: "partner.report.booking_by_pickup_location",
      BOOKING_BY_BUSINESS_TYPE: "partner.report.booking_by_business_type",

      NEW_CUSTOMERS: "partner.report.new_customers",
      CUSTOMERS_BY_RANK: "partner.report.customers_by_rank",
      LOYAL_CUSTOMERS: "partner.report.loyal_customers",
      CUSTOMERS_BY_SERVICE: "partner.report.customers_by_service",

      REVENUE_BY_DATE: "partner.report.revenue_by_date",
      REVENUE_BY_WEEK: "partner.report.revenue_by_week",
      REVENUE_BY_MONTH: "partner.report.revenue_by_month",
      REVENUE_BY_DRIVER: "partner.report.revenue_by_driver",

      COST_BY_DATE: "partner.report.cost_by_date",
      COST_BY_MONTH: "partner.report.cost_by_month",
    },

    EXPENDITURE: {
      TITLE: "partner.expenditure.title",
      HISTORY: "partner.expenditure.history",
      CATEGORIES: "partner.expenditure.categories",
    },

    SHOPPING: {
      EXPENDITURE_TITLE: "partner.shop.expenditure.title",
      EXPENDITURE_HISTORY: "partner.shop.expenditure.history",
      EXPENDITURE_CATEGORIES: "partner.shop.expenditure.categories",

      CLIENT_TITLE: "partner.shop.staff.title",
      CLIENT: "partner.shop.client",
      CLIENT_DETAIL: "partner.shop.client_detail",

      DASHBOARD: "partner.shop.dashboard",
      NOTICE_HISTORY: "partner.shop.notification_history",
      GROCERY_STORE_TITLE: "partner.shop.grocery_store.title",
      SALES_ORDER_TITLE: "partner.shop.sales_order.title",
      SETTING_TITLE: "partner.shop.setting",
      STOCK_TITLE: "partner.shop.stock",

      PRODUCT: "partner.shop.product",
      PRODUCT_DETAIL: "partner.shop.product_detail",
      PRODUCT_CATEGORY: "partner.shop.product_category",
      PRODUCT_CATEGORY_LIST: "partner.shop.product_category_list",
      MODIFIER: "partner.shop.modifier",
      COMBO: "partner.shop.combo",
      COMBO_DETAIL: "partner.shop.combo-detail",

      DELIVERY_TITLE: "partner.shop.delivery_title",
      DELIVERY: "partner.shop.delivery",
      DELIVERY_DETAIL: "partner.shop.delivery-detail",

      PRODUCT_STOCK: "partner.shop.grocery_store.product_stock",
      SUPPLIER: "partner.shop.grocery_store.supplier",
      STORE: "partner.shop.grocery_store.store",

      WAREHOUSE: "partner.shop.grocery_store.warehouse",
      GOOD_RECEIPT: "partner.shop.grocery_store.good_receipt",
      GOOD_ISSUE: "partner.shop.grocery_store.good_issue",
      STOCKTAKE: "partner.shop.grocery_store.stocktake",

      SALES_ORDER: "partner.shop.grocery_store.sales_order",
      SALE_RETURN: "partner.shop.grocery_store.salse_return",

      SALES_ORDER_DETAIL: "partner.shop.grocery_store.sales_order_detail",

      SETTING_ACCOUNT: "partner.shop.setting_account",
      UNIT: "partner.shop.unit",
      TAX: "partner.shop.tax",

      RECEIPT: "partner.shop.receipt",
      BILL: "partner.shop.bill",
      INVOICE: "partner.shop.invoice",

      REPORT: "partner.shop.report",
      REVENUE_SALE: "partner.shop.revenue_report",
      CUSTOMER: "partner.shop.customer_report",
      EXPENDITURE_REPORT: "partner.shop.expenditure_report",
      PROFIT_REPORT: "partner.shop.profit_report",

      PROMOTION_TITLE: "partner.shop.promotion_title",
      DISCOUNT: "partner.shop.discount",
      COUPON: "partner.shop.coupon",

      NOTIFICATION_TITLE: "partner.shop.notification_title",
      NOTIFICATION_TEMPLATE: "partner.shop.notification_template",
      NOTIFICATION_HISTORY: "partner.shop.notification_history",
      NOTIFICATION_AUTOMATION: "partner.shop.notification_automation",

      GROCERY_STORE: {},
    },

    TRAVEL: {},

    TRANSPORTATION: {
      TOUR_TITLE: "partner.transportation.tour_title",
      CONNECT_EGO_TITLE: "partner.transportation.connect_ego.title",
      BOOKING_TITLE: "partner.transportation.booking.title",
      CAR_MANAGEMENT_TITLE: "partner.transportation.car_management.title",

      RENTAL_INVOICES: "partner.transportation.invoice_rental",
      TAXI_INVOICES: "partner.transportation.invoice_taxi",
      CAR_MANAGEMENT: "partner.transportation.car_management",
      DRIVER_MANAGEMENT: "partner.transportation.driver_management",
      GARAGE_MANAGEMENT: "partner.transportation.garage_management",

      CONNECT_DRIVER: "partner.transportation.connect_driver",

      TAXI: {
        MAP: "partner.transportation.taxi.map",
        CONNECT_CAR_TAXI: "partner.transportation.connect_car_taxi",
        PRICE_CONFIG: "partner.transportation.taxi.price_config",
        BOOKING: "partner.transportation.taxi.booking",
        BOOKING_DETAIL: "partner.transportation.taxi.booking.detail",
        EGO_BOOKING: "partner.transportation.taxi.booking_ego",
        CONNECT_EGO_TITLE: "partner.transportation.connect_ego.title",
        PRICE_SETUP: "partner.transportation.taxi.price_setup",
      },

      TOUR: {
        PRICE_CONFIG: "partner.transportation.tour.price_config",
        BOOKING: "partner.transportation.tour.booking",
      },

      RENTAL: {
        BOOKING: "partner.transportation.rental.booking",
        MOTOBIKE: "partner.transportation.rental.motobike",
        CONNECT_CAR_RENT: "partner.transportation.rental.car_rental",
        BOOKING_TITLE: "partner.transportation.booking.title",
      },

      BUS_TICKET: {
        BOOKING: "partner.transportation.bus_ticket.booking",
      },

      HOTLINE: "partner.transportation.hotline",
    },
  },

  INVEST_MANAGER: {
    INVEST_UNIT: "ego.invest.unit",
    INVEST_ACCOUNT: "ego.invest.account",
    INVEST_ACCOUNT_DETAIL: "ego.invest.account.detials",
    INVEST_CAMPAIGN: "ego.invest.campaign",
    INVEST_CAMPAIGN_DETAIL: "ego.invest.campaign.details",
    INVEST_HISTORY: "ego.invest.history",
    INVEST_AUTH_PAYMENT: "ego.invest.auth.payment",
    INVEST_HISTORY_ADMIN: "ego.invest.auth.history.admin",
  },

  PARTNER_STAFF: {
    STAFF_TITLE: "partner_staff.staff.title",
    CLIENT_TITLE: "partner_staff.staff.title",
    SETTING_TITLE: "partner_staff.staff.title",

    PAYROLL: "partner_staff.payroll",
    CLIENT: "partner_staff.client",
    ACCOUNT: "partner_staff.account",
    DASHBOARD: "partner_staff.dashboard",
    DEPARTMENT: "partner_staff.department",
    POSITION: "partner_staff.position",

    CLIENT_DETAIL: "partner_staff.client_detail",
    STAFF_MANAGEMENT: "partner_staff.staff_management",

    REPORT_TITLE: "report_staff.title",
    REPORT_PAGE: "report_staff.title",

    SHOPPING: {
      GROCERY_STORE_TITLE: "partner_staff.shop.grocery_store.title",

      GROCERY_STORE: {
        PRODUCT: "partner_staff.shop.grocery_store.product",
        SALES_ORDER: "partner_staff.shop.grocery_store.sales_order",
        PRODUCT_STOCK: "partner_staff.shop.grocery_store.product_stock",
        PRODUCT_CATEGORY: "partner_staff.shop.grocery_store.product_category",
      },
    },

    TRAVEL: {},

    TRANSPORTATION: {
      CONNECT_EGO_TITLE: "partner_staff.transportation.connect_ego.title",
      CAR_MANAGEMENT_TITLE: "partner_staff.transportation.car_management.title",
      BOOKING_TITLE: "partner_staff.transportation.booking.title",

      RENTAL_INVOICES: "partner_staff.transportation.invoice_rental",
      TAXI_INVOICES: "partner_staff.transportation.invoice_taxi",
      CAR_MANAGEMENT: "partner_staff.transportation.car_management",
      DRIVER_MANAGEMENT: "partner_staff.transportation.driver_management",

      CONNECT_DRIVER: "partner_staff.transportation.connect_driver",

      TAXI: {
        MAP: "partner_staff.transportation.taxi.map",
        CONNECT_CAR_TAXI: "partner_staff.transportation.connect_car_taxi",
        PRICE_CONFIG: "partner_staff.transportation.taxi.price_config",
        BOOKING: "partner_staff.transportation.taxi.booking",
        EGO_BOOKING: "partner_staff.transportation.taxi.booking_ego",
      },

      RENTAL: {
        BOOKING: "partner_staff.transportation.rental.booking",
        CONNECT_CAR_RENT: "partner_staff.transportation.rental.car_rental",
      },

      BUS_TICKET: {
        BOOKING: "partner_staff.transportation.bus_ticket.booking",
      },

      HOTLINE: "partner_staff.transportation.hotline",
    },
  },

  EPOS: {
    DASHBOARD: 'epos.dashboard',
    BRANCH: 'epos.branch',
    EMPLOYEE: 'epos.employee',
    SETTING: 'epos.setting',
    CATEGORIES: 'epos.categories',
    PRODUCTS: 'epos.products',
    ORDER: 'epos.order',
  }
};
