export const PARTNER_ROUTES = {
  DASHBOARD: "/partner/dashboard",

  EGO_CONNECT: {
    CAR: "/partner/ego-connect/car",
    CAR_RENT: "/partner/ego-connect/driver",
    SETTING: "/partner/ego-connect/setting",
    BOOKING: "/partner/ego-connect/ego-booking",
  },

  BOOKING: {
    MAP: "/partner/map-driver",
    TAXI_INVOICES: "/partner/invoices-taxi",
    RENTAL_INVOICES: "/partner/invoices-rental",
    TAXI: "/partner/booking/taxi-booking",
    TAXI_DETAIL: "/partner/booking/taxi-detail",
    BUS_SHEDULE: "/partner/bus-shedule-setup",
    BUS_BOOKING: "/partner/bus-booking",
    EGO_RENTAL_BOOKING: "/partner/booking/ego-rental-booking",
    RENTAL_BOOKING: "/partner/booking/rental-booking",
    HOTLINE: "/create-booking-partner",
  },

  CAR_DRIVER: {
    MOTO: "/partner/car-driver-management/moto",
    CAR: "/partner/car-driver-management/car",
    CAR_DETAIL: "/partner/car-detail/:id",
    MOTO_DETAIL: "/partner/moto-detail/:id",
    BUS: "/partner/car-driver-management/bus",
    GARAGE: "/partner/car-driver-management/garage",
    DRIVER: "/partner/car-driver-management/driver",
    DRIVER_DETAIL: "/partner/driver-detail",
  },

  STAFF: {
    LIST: "/partner/staff/list",
    PAYROLL: "/partner/staff/payroll",
  },

  CLIENT: {
    LIST: "/partner/client/list",
    CAR_HOST: "/partner/car-manager",
    DELETE_LIST: "/partner/client/delete-client",
    DETAIL_CLIENT: "/partner/client/detail",
  },

  EXPENDITURE: {
    HISTORY: "/partner/expenditure/history",
    ITEMS: "/partner/expenditure/items-setup",
    CATEGORIES: "/partner/expenditure/categories-list",
  },

  REPORT: {
    MAIN: "/partner/report-pages",

    DAY_REVENUE: "/partner/report-pages/partner.report.revenue_by_date",
    WEEK_REVENUE: "/partner/report-pages/partner.report.revenue_by_week",
    MONTH_REVENUE: "/partner/report-pages/partner.report.revenue_by_month",
    SERVICE_REVENUE: "/partner/report/service-revenue",
    LOCATION_REVENUE: "partner/report/location-revenue",
    DRIVER_REVENUE: "/partner/report/driver-revenue",

    DAY_BOOKINGS: "/partner/report-pages/partner.report.booking_by_date",
    WEEK_BOOKINGS: "/partner/report-pages/partner.report.booking_by_week",
    MONTH_BOOKINGS: "/partner/report-pages/partner.report.booking_by_month",

    NEW_CLIENTS: "/partner/report-pages/partner.report.new_customers",

    DAY_EXPENSES: "/partner/report-pages/partner.report.cost_by_date",
    MONTH_EXPENSES: "/partner/report-pages/partner.report.cost_by_month",
  },

  SETTING: {
    INFO: "/partner/basic-info",
    DEPARTMENT: "/partner/department",
    POSITION_STAFF: "/partner/position-staff",
    RENTAL_CAR_CONFIG: "/partner/rental-car-config",
    PRICE_CONFIG: "/partner/price-config",

    WORKPLACE: "/partner/work-place/list",
    WARE_HOUSE: "/partner/ware-house-setting",
    SERVICE: "/partner/service",
    TICKET_CATEGORIES: "/partner/ticket-categories",
    BUS_CATEGORIES: "/partner/bus_category",
    SCHEDULE: "/partner/schedule",
    // INCURRD_FEE: '/partner/incurred-fee'
  },

  SHOP: {
    INFO: "/partner/basic-info-shopping",
    DASHBOARD: "/partner/shop/dashboard",
    PRODUCT_CATEGORY: "/partner/product-category",
    PRODUCT_CATEGORY_LIST: "/partner/product-category-list",
    PRODUCT: "/partner/product",
    PRODUCT_DETAIL: "/partner/product-detail",
    PRODUCT_STOCK: "/partner/product-stock",
    SALE_ORDER: "/partner/sale-order",
    SALE_RETURN: "/partner/sale-return",

    SALE_ORDER_DETAIL: "/partner/sale-order-detail",

    MODIFIER: "/partner/shop/modifier",
    MODIFIER_LIST: "/partner/shop/modifier-list",
    COMBO: "/parner/shop/combo",
    COMBO_DETAIL: "/parner/shop/combo-detail",

    CLIENT: "/partner/shop/client",
    DETAIL_CLIENT: "/partner/shop/client/detail",
    CLIENT_GROUP: '/partner/shop/client-group',

    UNIT: "/partner/shop/unit",
    DELIVERY: "/partner/shop/delivery",
    DELIVERY_DETAIL: "/partner/shop/delivery-detail",
    INVOICE: "/partner/shop/invoice",

    WARE_HOUSE: "/partner/shop/warehouse",
    GOOD_RECEIPT: "/partner/shop/good-receipt",
    GOOD_ISSUE: "/partner/shop/good-issue",
    STOCKTAKE: "/partner/shop/stocktake",
    SUPPLIER: "/partner/shop/supplier",

    COUPON: '/partner/shop/coupon',
    DISCOUNT: '/partner/shop/discount',
    CREATE_DISCOUNT: '/partner/shop/discount-create',

    EXPENDITURE_CATEGORIES: '/partner/shop/expenditor-category',
    EXPENDITURE_HISTORY: '/partner/shop/expenditor-history',
  },

  TOUR_ROUTES: {
    PRICE: "/partner/tour-price-for-booking",
    BOOKING: "/partner/tour-booking",
  },

  TAXI: {
    PRICE_SETTINGS: '/partner/taxi-price-settings'
  },

  EPOS: {
    DASHBOARD: '/epos/dashboard',
    BRANCH: '/epos/branch',
    EMPLOYEE: '/epos/staff',
    SETTING: '/epos/setting',
    CATEGORIES: '/epos/categories',
    PRODUCTS: '/epos/products',
    ORDER: '/epos/order',
  }
};
